.create-tournament-btn {
  width: 500px;
  font-size: 1.5em;
  max-width: 90%;
  display: block;
  margin: 1em auto;
  padding: .5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 10px;
  background: #ff553e;
  font-weight: bold;
  color: #fff;
}