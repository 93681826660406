@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* FONTS */ /*Roboto*/

.App {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 140%;
}

.app-header {
  background: url(/static/media/top-blob.4d311187.svg);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 140%;
}

.app-title {
  color: #ff553e;
  font-size: 3em;
  padding: 1em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  display: block;
  /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #eee; */
}

.smooth-transition {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

button, div {
  outline: none;
}

.stop-scroll {
  overflow: hidden;
}
.create-tournament-btn {
  width: 500px;
  font-size: 1.5em;
  max-width: 90%;
  display: block;
  margin: 1em auto;
  padding: .5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 10px;
  background: #ff553e;
  font-weight: bold;
  color: #fff;
}
.create-tournament-modal-dark-background{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.851);
}

.create-tournament-modal {
  width: 94%;
  max-height: 80vh;
  margin: 0 auto;
  position: fixed;
  top: 10vh;
  left: 3%;
  background: white;
  /* border: 1px solid #777; */
  border-radius: 20px;
  overflow-y: scroll;
}
.add-players-title {
  text-align: center;
  color: #317bd4;
  font-weight: bold;
  font-size: 2em;
  font-weight: 400;
  letter-spacing: 5px;
  margin: 1em 0;
}

.player-name-input-wrapper {
  position: relative;
}

.player-name-input-label {
  position: absolute;
  font-size: .7em;
  font-weight: 500;
  top: -7px;
  left: 34px;
  color: #777;
  background: white;
  padding: 0 8px;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.player-name-input-wrapper:focus-within .player-name-input-label {
  color: #317bd4;
}

.player-name-input {
  display: block;
  font-size: .85em;
  width: 80%;
  padding: 8px;
  margin-top: .2em;
  border: none;
  box-shadow: 0px 1px 2px 0px #e4e4e4;
  border-radius: 7px;
  color: #444;
  font-size: 1.2em;
  padding: .7em;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  margin: 1em auto;
  outline: none;
}

.player-name-input-wrapper:first-child {
  margin-top: 2.3em;
}

.add-player-btn {
  width: 86%;
  margin: 1.4em auto;
  margin-bottom: 0;
  text-align: center;
  display: block;
  font-size: 1.2em;
  padding: .7em;
  border-radius: 5px;
  /* border: 1px solid #317bd4; */
  border: none;
  background: #76a3ff;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.generate-tournament-btns-wrapper {
  display: flex;
  margin: 0 auto;
  margin-bottom: 4em;
  max-width: 86%;
}

.generate-tournament-btn {
  margin: 1.4em auto;
  width: 49%;
  text-align: center;
  display: inline-block;
  font-size: 1.1em;
  padding: .7em;
  border-radius: 5px;
  border: 1px solid #317bd4;
  background: #317bd4;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.generate-cup {
  border: none;
  background: #9fd09b;
  margin-left: 0;
}

.generate-league {
  border: none;
  background: #9fd09b;
  margin-right: 0;
}
.no-tournaments-msg {
  text-align: center;
  color: #aaa;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 1.2em;
}

.tournament-container {
  /* width: 260px; */
  max-width: 90%;
  margin: .4em auto;
  box-sizing: border-box;
  padding: .6em;
  /* border: 2px solid #eaeaea; */
  /* box-shadow: 0px 0px 3px 0px #d2d2d2; */
  background: #fefefe;
  border-radius: 10px;
  padding-left: .8em;
  color: #333;
  display: flex;
}

.tournament-title {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 6px;
}

.tournament-trophy-icon-container {
  max-width: 20%;
  width: 80px;
  display: inline-block;
  padding: 15px;
  /* border: 3px solid #58a0e6e3; */
  /* box-shadow: 0px 0px 3px 0px #d2d2d2; */
  border-radius: 50%;
  box-sizing: border-box;
}

.trophy-icon {
  max-width: 100%;
}

.tournamnet-details-container {
  display: inline-block;
  width: 80%;
  padding: 15px;
  box-sizing: border-box;
}
.tournament-table-results {
  position: -webkit-sticky;
  position: sticky;
  bottom: -9em;
  width: 98%;
  /* padding-bottom: 5em; */
  margin: 0 auto;
  box-shadow: 1px 1px 7px 0px #00000048;
  background: white;
  border-radius: 10px;
  font-family: Roboto;
  min-height: 200px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 9999;
}

.dragged-table {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 9999;
}

.tournament-table-results-names, .tournament-table-results-numbers {
  display: table-cell;
  width: 50%;
  border-collapse: collapse;
}

.tournament-table-results-names {
  text-align: left;
}

.tournament-table-draggable {
  text-align: center;
}

.long-dash {
  height: 3px;
  display: inline-block;
  width: 40px;
  background: #e66262;
}


.table-results-table-header {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  /* padding: .4em; */
}

.table-results-names {
  font-weight: 400;
  font-size: 14px;
  border-top: 2px solid #eee;
  padding: .6em 0;
}

.table-results-names:last-child, .tournament-tables tr:last-child .table-results-numbers {
  border-bottom: 2px solid #eee;
}

.table-results-numbers {
  font-weight: 400;
  font-size: 14px;
  border-top: 2px solid #eee;
  padding: .6em 0;
}

.tournament-tables {
  border-collapse: collapse;
  width: 100%;
  /* width: 50%; */
}

.table-results-numbers-header th, .table-results-name-header th{
  padding: 1.5em;
}

.table-results-names {
  padding-left: 1em;
  border-left: 4px solid crimson;
}

/* .horizontal-line {
  width: 100%;
  height: 3px;
  border-bottom: 1px solid #333;
} */
.tournament-details-wrapper {
  display: none;
  width: 0px;
  height: 0px;
  position: fixed;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tournament-details-wrapper-active {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  height: 100vh;
  top: 0;
  right: 0;
  background-image: url(/static/media/rounds-background-blob-light-orange.d07bfa71.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  background-color: white; /*#cee1ec;*/
  z-index: 10;
  overflow-y: scroll;
}

.close-tournament-details-btn {
  color: #dc4545;
  position: fixed;
  font-weight: bold;
  right: -8px;
  top: -6px;
  text-align: left;
  padding: .8em;
  border-radius: 17px;
  border-top-right-radius: 0;
  background: #f9cdcd;
  /* border-top-left-radius: 0; */
  border-bottom-right-radius: 0;
  z-index: 99;
}

.details-tournament-title {
  text-align: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 1em;
  max-width: 67%;
  margin: 0 auto;
  background: #dc4545;
  color: white;
  font-weight: bold;
  margin-bottom: 2em;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

/* L E A G U E    M A T C H E S */

.round-container-wrapper {
  max-width: 90%;
  margin: 10px auto;
  /* border: 1px solid #ececec; */
  box-shadow: 1px 1px 5px 0px #00000018;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.round-details-container {
  padding: 5px 20px;
  background: #fff;
}
.round-title {
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 20px;
  padding-bottom: 0;
  background: #fff;
  color: #444;
}
.match-container {
  padding: 15px 5px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-around
}
.match-team1, .match-team2 {
  width: 40%;
  text-transform: capitalize;
  font-weight: bold;
  color: #444;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.match-team1 {
}
.match-team2 {
  text-align: right
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


.match-score-team1, .match-score-team2 {
  display: inline-block;
  position: absolute;
  padding: 10px 20px;
  top: 0;
  border-radius: 5px;
  background: #fff;
  font-weight: bold;
  border: none;
  max-width: 10%;
  outline: none;
}
.is-finished-checkbox {
  padding: 11px 0;
}
.match-score-team1 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  right: 0;
}
.match-score-team2 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  left: 0;
}

.match-vs {
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;
  font-weight: bold;
}


/* Styling Checkbox Starts */
.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: -2px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #e66262;
}


.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #e66262;
}


.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 6px;
  top: 1px;
  width: 4px;
  height: 11px;
  border-radius: 5px;
  border: solid #e66262;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
-webkit-transform: rotate(45deg) scale(1);
transform: rotate(45deg) scale(1);
opacity:1;
left: 6px;
top: 1px;
width: 4px;
height: 11px;
border: solid #e66262;
border-width: 0 2px 2px 0;
background-color: transparent;
border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  -webkit-transform: scale(0);
  transform: scale(0);    
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  transform: scale(3);
  opacity:0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.brackets-container {
  width: 100%;
  min-height: 1107px;
  margin: 0 auto;
  -webkit-scroll-snap-points-x: repeat(125px);
      -ms-scroll-snap-points-x: repeat(125px);
          scroll-snap-points-x: repeat(125px);
  overflow: auto;
  white-space: nowrap;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch
}

.round-title-cup {
  text-align: center;
  background: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #b02e30;
}

.round-title-final {
  padding: 2px;
  background: #c86363;
  color: white;
  font-weight: bold;
  letter-spacing: 5px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-transform: uppercase;
  text-align: center;
}

.round-container {
  scroll-snap-align: start;
}

.round-outter-wrapper {}

.round-matches {
  position: relative
}

.round-16 {
  padding-left: 1em;
}

.round-match {
  width: 160px;
  height: 90px;
  border: 1px solid #edc6c7;
  margin: 20px 0;
  box-sizing: content-box;
  border-radius: 16px;
  background: white;
  position: relative;
  z-index: 9;
  -webkit-transition: all ease-in 0.4s;
  transition: all ease-in 0.4s;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.round-match-final {
  border-top-left-radius: 16px;
  height: 112px;
}

.na-player {
  color: #777;
  letter-spacing: 3px;
  text-transform: lowercase;
  display: block;
  text-align: center;
  margin-left: -27px;
}

.bye-player, .tbd-player {
  color: #777;
  display: block;
  text-align: center;
  margin-left: -30px;
}

.round-match-team {
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  border-left: 8px solid blue;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #2d2d2d;
}

.round-match-team:nth-child(1) {
  border-left: 8px solid #d56f6e;
  border-top-left-radius: 0px;
  border-top-right-radius: 16px;
  background: #ffd5d51a;
}

.round-match-team:nth-child(2) {
  border-left: 8px solid #eda1a1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
  background: #fff8f8;
}

.round-match-final .round-match-team:nth-child(2) {
  border-left: 8px solid #f9cdcd;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #ffd5d51a;
}

.round-match-final .round-match-team:nth-child(3) {
  border-left: 8px solid #eda1a1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
  background: #fff8f8;
}

.top-horizontal-dash,
.vertical-dash,
.bottom-horizontal-dash {
  position: absolute;
  width: 180px;
  top: 45px;
  background-color: #FFF;
  border: 1px solid #aaa;
  box-sizing: border-box;
  border-left: 0;
  left: 0;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  border-top-right-radius: 4px;
  background: none;
  border-color: #eda1a1;
}

.top-horizontal-dash {
  width: 180px;
  top: 45px;
  border-left: 0;
  border: none;
  left: 0;
  border-top-right-radius: 4px;
}

.vertical-dash {
  height: 140px;
  width: 1px;
  top: 45px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  left: 200px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.bottom-horizontal-dash {
  position: absolute;
  width: 170px;
  bottom: 45px;
  border-left: 0;
  left: 0;
  border-bottom-right-radius: 4px;
}

.round-8 .bottom-horizontal-dash {
  width: 179px;
  left: -9px;
}

.round-8 .round-matches,
.round-4 .round-matches {
  margin-left: 18px;
}

.round-8 .round-match,
.round-4 .round-match {
  margin: 130px 0;
}

.round-8 .round-match {
  margin-top: 75px;
}

.round-4 .round-match {
  margin: 360px 0;
}

.round-4 .round-match {
  margin-top: 185px;
}

.round-8 .top-horizontal-dash,
.round-4 .bottom-horizontal-dash {
  width: 179px;
  left: -9px;
}

.round-final .round-matches {
  margin-left: 18px;
}

.round-final .round-match {
  margin: 400px 0;
}

.round-final .bottom-horizontal-dash {
  left: -9px;
}
