.tournament-details-wrapper {
  display: none;
  width: 0px;
  height: 0px;
  position: fixed;
  transition: all 0.5s ease;
}

.tournament-details-wrapper-active {
  transition: all 0.5s ease;
  display: block;
  position: fixed;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  height: 100vh;
  top: 0;
  right: 0;
  background-image: url(../../images/rounds-background-blob-light-orange.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  background-color: white; /*#cee1ec;*/
  z-index: 10;
  overflow-y: scroll;
}

.close-tournament-details-btn {
  color: #dc4545;
  position: fixed;
  font-weight: bold;
  right: -8px;
  top: -6px;
  text-align: left;
  padding: .8em;
  border-radius: 17px;
  border-top-right-radius: 0;
  background: #f9cdcd;
  /* border-top-left-radius: 0; */
  border-bottom-right-radius: 0;
  z-index: 99;
}

.details-tournament-title {
  text-align: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 1em;
  max-width: 67%;
  margin: 0 auto;
  background: #dc4545;
  color: white;
  font-weight: bold;
  margin-bottom: 2em;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  position: sticky;
  top: 0;
  z-index: 99;
}

/* L E A G U E    M A T C H E S */

.round-container-wrapper {
  max-width: 90%;
  margin: 10px auto;
  /* border: 1px solid #ececec; */
  box-shadow: 1px 1px 5px 0px #00000018;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.round-details-container {
  padding: 5px 20px;
  background: #fff;
}
.round-title {
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 20px;
  padding-bottom: 0;
  background: #fff;
  color: #444;
}
.match-container {
  padding: 15px 5px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-around
}
.match-team1, .match-team2 {
  width: 40%;
  text-transform: capitalize;
  font-weight: bold;
  color: #444;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.match-team1 {
}
.match-team2 {
  text-align: right
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


.match-score-team1, .match-score-team2 {
  display: inline-block;
  position: absolute;
  padding: 10px 20px;
  top: 0;
  border-radius: 5px;
  background: #fff;
  font-weight: bold;
  border: none;
  max-width: 10%;
  outline: none;
}
.is-finished-checkbox {
  padding: 11px 0;
}
.match-score-team1 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  right: 0;
}
.match-score-team2 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  left: 0;
}

.match-vs {
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;
  font-weight: bold;
}


/* Styling Checkbox Starts */
.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: -2px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #e66262;
}


.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #e66262;
}


.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 6px;
  top: 1px;
  width: 4px;
  height: 11px;
  border-radius: 5px;
  border: solid #e66262;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
-webkit-transform: rotate(45deg) scale(1);
-ms-transform: rotate(45deg) scale(1);
transform: rotate(45deg) scale(1);
opacity:1;
left: 6px;
top: 1px;
width: 4px;
height: 11px;
border: solid #e66262;
border-width: 0 2px 2px 0;
background-color: transparent;
border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);    
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity:0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}