.add-players-title {
  text-align: center;
  color: #317bd4;
  font-weight: bold;
  font-size: 2em;
  font-weight: 400;
  letter-spacing: 5px;
  margin: 1em 0;
}

.player-name-input-wrapper {
  position: relative;
}

.player-name-input-label {
  position: absolute;
  font-size: .7em;
  font-weight: 500;
  top: -7px;
  left: 34px;
  color: #777;
  background: white;
  padding: 0 8px;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.player-name-input-wrapper:focus-within .player-name-input-label {
  color: #317bd4;
}

.player-name-input {
  display: block;
  font-size: .85em;
  width: 80%;
  padding: 8px;
  margin-top: .2em;
  border: none;
  box-shadow: 0px 1px 2px 0px #e4e4e4;
  border-radius: 7px;
  color: #444;
  font-size: 1.2em;
  padding: .7em;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  margin: 1em auto;
  outline: none;
}

.player-name-input-wrapper:first-child {
  margin-top: 2.3em;
}

.add-player-btn {
  width: 86%;
  margin: 1.4em auto;
  margin-bottom: 0;
  text-align: center;
  display: block;
  font-size: 1.2em;
  padding: .7em;
  border-radius: 5px;
  /* border: 1px solid #317bd4; */
  border: none;
  background: #76a3ff;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.generate-tournament-btns-wrapper {
  display: flex;
  margin: 0 auto;
  margin-bottom: 4em;
  max-width: 86%;
}

.generate-tournament-btn {
  margin: 1.4em auto;
  width: 49%;
  text-align: center;
  display: inline-block;
  font-size: 1.1em;
  padding: .7em;
  border-radius: 5px;
  border: 1px solid #317bd4;
  background: #317bd4;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.generate-cup {
  border: none;
  background: #9fd09b;
  margin-left: 0;
}

.generate-league {
  border: none;
  background: #9fd09b;
  margin-right: 0;
}