.create-tournament-modal-dark-background{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.851);
}

.create-tournament-modal {
  width: 94%;
  max-height: 80vh;
  margin: 0 auto;
  position: fixed;
  top: 10vh;
  left: 3%;
  background: white;
  /* border: 1px solid #777; */
  border-radius: 20px;
  overflow-y: scroll;
}