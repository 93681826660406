.tournament-table-results {
  position: sticky;
  bottom: -9em;
  width: 98%;
  /* padding-bottom: 5em; */
  margin: 0 auto;
  box-shadow: 1px 1px 7px 0px #00000048;
  background: white;
  border-radius: 10px;
  font-family: Roboto;
  min-height: 200px;
  transition: all 0.5s ease;
  z-index: 9999;
}

.dragged-table {
  position: sticky;
  bottom: 0;
  z-index: 9999;
}

.tournament-table-results-names, .tournament-table-results-numbers {
  display: table-cell;
  width: 50%;
  border-collapse: collapse;
}

.tournament-table-results-names {
  text-align: left;
}

.tournament-table-draggable {
  text-align: center;
}

.long-dash {
  height: 3px;
  display: inline-block;
  width: 40px;
  background: #e66262;
}


.table-results-table-header {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  /* padding: .4em; */
}

.table-results-names {
  font-weight: 400;
  font-size: 14px;
  border-top: 2px solid #eee;
  padding: .6em 0;
}

.table-results-names:last-child, .tournament-tables tr:last-child .table-results-numbers {
  border-bottom: 2px solid #eee;
}

.table-results-numbers {
  font-weight: 400;
  font-size: 14px;
  border-top: 2px solid #eee;
  padding: .6em 0;
}

.tournament-tables {
  border-collapse: collapse;
  width: 100%;
  /* width: 50%; */
}

.table-results-numbers-header th, .table-results-name-header th{
  padding: 1.5em;
}

.table-results-names {
  padding-left: 1em;
  border-left: 4px solid crimson;
}

/* .horizontal-line {
  width: 100%;
  height: 3px;
  border-bottom: 1px solid #333;
} */