/* FONTS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap'); /*Roboto*/

.App {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 140%;
}

.app-header {
  background: url(./images/top-blob.svg);
  position: sticky;
  top: 0;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 140%;
}

.app-title {
  color: #ff553e;
  font-size: 3em;
  padding: 1em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  display: block;
  /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #eee; */
}

.smooth-transition {
  transition: all 0.5s ease;
}

button, div {
  outline: none;
}

.stop-scroll {
  overflow: hidden;
}