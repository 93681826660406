.no-tournaments-msg {
  text-align: center;
  color: #aaa;
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 1.2em;
}

.tournament-container {
  /* width: 260px; */
  max-width: 90%;
  margin: .4em auto;
  box-sizing: border-box;
  padding: .6em;
  /* border: 2px solid #eaeaea; */
  /* box-shadow: 0px 0px 3px 0px #d2d2d2; */
  background: #fefefe;
  border-radius: 10px;
  padding-left: .8em;
  color: #333;
  display: flex;
}

.tournament-title {
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 6px;
}

.tournament-trophy-icon-container {
  max-width: 20%;
  width: 80px;
  display: inline-block;
  padding: 15px;
  /* border: 3px solid #58a0e6e3; */
  /* box-shadow: 0px 0px 3px 0px #d2d2d2; */
  border-radius: 50%;
  box-sizing: border-box;
}

.trophy-icon {
  max-width: 100%;
}

.tournamnet-details-container {
  display: inline-block;
  width: 80%;
  padding: 15px;
  box-sizing: border-box;
}