.brackets-container {
  width: 100%;
  min-height: 1107px;
  margin: 0 auto;
  scroll-snap-points-x: repeat(125px);
  overflow: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch
}

.round-title-cup {
  text-align: center;
  background: none;
  font-weight: bold;
  text-transform: uppercase;
  color: #b02e30;
}

.round-title-final {
  padding: 2px;
  background: #c86363;
  color: white;
  font-weight: bold;
  letter-spacing: 5px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-transform: uppercase;
  text-align: center;
}

.round-container {
  scroll-snap-align: start;
}

.round-outter-wrapper {}

.round-matches {
  position: relative
}

.round-16 {
  padding-left: 1em;
}

.round-match {
  width: 160px;
  height: 90px;
  border: 1px solid #edc6c7;
  margin: 20px 0;
  box-sizing: content-box;
  border-radius: 16px;
  background: white;
  position: relative;
  z-index: 9;
  transition: all ease-in 0.4s;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.round-match-final {
  border-top-left-radius: 16px;
  height: 112px;
}

.na-player {
  color: #777;
  letter-spacing: 3px;
  text-transform: lowercase;
  display: block;
  text-align: center;
  margin-left: -27px;
}

.bye-player, .tbd-player {
  color: #777;
  display: block;
  text-align: center;
  margin-left: -30px;
}

.round-match-team {
  height: 45px;
  line-height: 45px;
  padding-left: 15px;
  border-left: 8px solid blue;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #2d2d2d;
}

.round-match-team:nth-child(1) {
  border-left: 8px solid #d56f6e;
  border-top-left-radius: 0px;
  border-top-right-radius: 16px;
  background: #ffd5d51a;
}

.round-match-team:nth-child(2) {
  border-left: 8px solid #eda1a1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
  background: #fff8f8;
}

.round-match-final .round-match-team:nth-child(2) {
  border-left: 8px solid #f9cdcd;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #ffd5d51a;
}

.round-match-final .round-match-team:nth-child(3) {
  border-left: 8px solid #eda1a1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
  background: #fff8f8;
}

.top-horizontal-dash,
.vertical-dash,
.bottom-horizontal-dash {
  position: absolute;
  width: 180px;
  top: 45px;
  background-color: #FFF;
  border: 1px solid #aaa;
  box-sizing: border-box;
  border-left: 0;
  left: 0;
  transform-origin: top right;
  border-top-right-radius: 4px;
  background: none;
  border-color: #eda1a1;
}

.top-horizontal-dash {
  width: 180px;
  top: 45px;
  border-left: 0;
  border: none;
  left: 0;
  border-top-right-radius: 4px;
}

.vertical-dash {
  height: 140px;
  width: 1px;
  top: 45px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  left: 200px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.bottom-horizontal-dash {
  position: absolute;
  width: 170px;
  bottom: 45px;
  border-left: 0;
  left: 0;
  border-bottom-right-radius: 4px;
}

.round-8 .bottom-horizontal-dash {
  width: 179px;
  left: -9px;
}

.round-8 .round-matches,
.round-4 .round-matches {
  margin-left: 18px;
}

.round-8 .round-match,
.round-4 .round-match {
  margin: 130px 0;
}

.round-8 .round-match {
  margin-top: 75px;
}

.round-4 .round-match {
  margin: 360px 0;
}

.round-4 .round-match {
  margin-top: 185px;
}

.round-8 .top-horizontal-dash,
.round-4 .bottom-horizontal-dash {
  width: 179px;
  left: -9px;
}

.round-final .round-matches {
  margin-left: 18px;
}

.round-final .round-match {
  margin: 400px 0;
}

.round-final .bottom-horizontal-dash {
  left: -9px;
}